import "styles/pages/page-blog.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";

import Breadcrumps from "components/Breadcrumps";

import { Content } from "page_components/search";

const BlogSearch = () => {
  return (
    <Layout>
      <Seo title="Blog" />
      <Breadcrumps data="Blog" />
      <Content />
    </Layout>
  );
};

export default BlogSearch;
